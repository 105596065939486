import React, { useState } from 'react';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { RichText } from 'prismic-reactjs';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

import usePagination from '../utils/usePagination';

import SvgArrowLeft from '../assets/images/vectors/icons/arrow-left-sm.inline.svg';
import SvgArrowRight from '../assets/images/vectors/icons/arrow-right-sm.inline.svg';

import '../assets/styles/pages/blog-page.scss';

const filterPosts = (list) => list.filter((item) => item.node.image && item.node.imageSharp);

const BlogPage = ({ data: { prismic } }) => {
  const allMdPosts = filterPosts(prismic.allMdPosts.edges);
  const allSmPosts = filterPosts(prismic.allSmPosts.edges);
  const allPosts = filterPosts(prismic.allPosts.edges);

  return (
    <Layout>
      <SEO title="Blog" />
      <div className="blog-page">
        <FirstSection />

        <SecondSection prismicMdPosts={allMdPosts} prismicSmPosts={allSmPosts} />

        <ThirdSection
          prismicAllPosts={allPosts}
          prismicAllCategories={prismic.allCategories.edges}
        />
      </div>
    </Layout>
  );
};

const FirstSection = () => (
  <section className="first-section">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="first-section__wrapper">
            <h1 className="c-h1">The Pursuit of Knowledge Begins Here</h1>
            <p>
              We’ve developed a wide array of resources to help you make better decisions, leverage
              new technologies, and scale your business.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const SecondSection = ({ prismicMdPosts, prismicSmPosts }) => {
  const mdPost = prismicMdPosts.slice(0, 1);
  const smPosts = prismicSmPosts.slice(1, 4);

  return (
    <section className="c-section-mb second-section">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-lg-7 col-xl-8 second-section__left">
            {mdPost.map(({ node }) => (
              <Link
                to={`/blog/${node._meta.uid}/`}
                className="second-section__left__post"
                key={node._meta.uid}
              >
                <div className="second-section__left__post__image">
                  <Img fluid={node.imageSharp.childImageSharp.fluid} />
                </div>
                <div className="second-section__left__post__text">
                  <span className="c-caption-2">{RichText.asText(node.category.category)}</span>
                  <h3 className="c-h5--bold">{RichText.asText(node.title)}</h3>
                  <p>{`${RichText.asText(node.content).slice(0, 300)}...`}</p>
                </div>
              </Link>
            ))}
          </div>
          <div className="col-md-6 col-lg-5 col-xl-4 second-section__right">
            {smPosts.map(({ node }) => (
              <Link
                to={`/blog/${node._meta.uid}/`}
                className="second-section__right__post"
                key={node._meta.uid}
              >
                <div className="second-section__right__post__image">
                  <Img fluid={node.imageSharp.childImageSharp.fluid} />
                </div>
                <div className="second-section__right__post__text">
                  <span className="c-caption-2">{RichText.asText(node.category.category)}</span>
                  <h3 className="c-text-1--bold">{RichText.asText(node.title)}</h3>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

const POSTS_PER_PAGE = 8;

const ThirdSection = ({ prismicAllPosts, prismicAllCategories }) => {
  const [activeTab, setActiveTab] = useState('all');

  const setNewActiveTab = (tabName) => {
    setActiveTab(tabName);
  };

  const getActivePosts = () =>
    activeTab === 'all'
      ? prismicAllPosts
      : prismicAllPosts.filter(
          ({ node }) => RichText.asText(node.category.category).toLowerCase() === activeTab
        );
  const activePosts = getActivePosts();

  const { next, prev, jump, currentData, currentPage, maxPage } = usePagination(
    activePosts,
    POSTS_PER_PAGE
  );

  console.log('currentData', currentData());

  return (
    <section className="third-section">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="third-section__categories">
              <span
                className={activeTab === 'all' ? 'active-category' : ''}
                onClick={() => setNewActiveTab('all')}
              >
                All
              </span>
              {prismicAllCategories.map(({ node }, index) => (
                <span
                  key={index}
                  className={
                    activeTab === RichText.asText(node.category).toLowerCase()
                      ? 'active-category'
                      : ''
                  }
                  onClick={() => setNewActiveTab(RichText.asText(node.category).toLowerCase())}
                >
                  {RichText.asText(node.category)}
                </span>
              ))}
            </div>

            <div className="third-section__posts">
              {currentData().map(({ node }) => (
                <Link
                  to={`/blog/${node._meta.uid}/`}
                  className="third-section__posts__post"
                  key={node._meta.uid}
                >
                  <div className="third-section__posts__post__image">
                    <Img fluid={node.imageSharp.childImageSharp.fluid} />
                  </div>
                  <div className="third-section__posts__post__text">
                    <span className="c-caption-2">Latest</span>
                    <h3 className="c-text-1--bold">{RichText.asText(node.title)}</h3>
                    <p>{`${RichText.asText(node.content).slice(0, 320)}...`}</p>
                  </div>
                </Link>
              ))}
            </div>

            {activePosts.length > POSTS_PER_PAGE && (
              <div className="third-section__pagination">
                <div className="third-section__pagination__left" onClick={() => prev()}>
                  <SvgArrowLeft />
                </div>
                <div className="third-section__pagination__numbers">
                  {[...Array(maxPage).keys()].map((number) => (
                    <span
                      key={number}
                      className={number + 1 === currentPage ? 'active-page' : ''}
                      onClick={() => jump(number + 1)}
                    >
                      {number + 1}
                    </span>
                  ))}
                </div>
                <div className="third-section__pagination__right" onClick={() => next()}>
                  <SvgArrowRight />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogPage;

export const pageQuery = graphql`
  query BlogPage {
    prismic {
      allMdPosts: allBlogs(first: 1, sortBy: date_DESC) {
        edges {
          node {
            _meta {
              uid
            }
            title
            category {
              ... on PRISMIC_Blog_category {
                category
              }
            }
            content
            image
            imageSharp {
              childImageSharp {
                fluid(maxWidth: 400, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
      allSmPosts: allBlogs(first: 4, sortBy: date_DESC) {
        edges {
          node {
            _meta {
              uid
            }
            title
            category {
              ... on PRISMIC_Blog_category {
                category
              }
            }
            image
            imageSharp {
              childImageSharp {
                fluid(maxWidth: 120, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }

      allPosts: allBlogs(sortBy: date_DESC) {
        edges {
          node {
            _meta {
              uid
            }
            title
            category {
              ... on PRISMIC_Blog_category {
                category
              }
            }
            content
            image
            imageSharp {
              childImageSharp {
                fluid(maxWidth: 380, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }

      allCategories: allBlog_categorys {
        edges {
          node {
            category
          }
        }
      }
    }
  }
`;
